import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "./form";
import { register, checkEmail } from "../../../services/auth";

import { CustomizedSnackbars } from "../../Message";

const styles = theme => ({});

const validationSchema = Yup.object({
  name: Yup.string("Informe seu nome").required("Nome é obrigatório"),
  email: Yup.string("Informe seu e-mail")
    .email("Informe um e-mail válido")
    .required("e-mail é obrigatório"),
  password: Yup.string("")
    .min(6, "Sua senha deve conter mínimo de 6 caracteres")
    .required("Informe uma senha"),
  confirmPassword: Yup.string("Informe uma senha")
    .required("Confirme sua senha")
    .oneOf([Yup.ref("password")], "As senhas não conferem"),
});

class SignUpForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      mgs: "",
      redirectToReferrer: false,
    };
  }

  componentDidMount() {
    // this.setStatus("error", "TESTE");
  }

  setStatus = (status, message) => {
    this.setState({ status: status, msg: message, date: new Date() });
  };

  checkemail = async (name, e) => {
    const email = e.target.value;

    const res = await checkEmail(email);
    if (res.error > 0) {
      console.log("res", res);
      this.setStatus("error", "Houve um erro inesperado.");
      return;
    }

    if (res.exists === true) {
      this.setStatus("warning", "Endereço de e-mail já cadastrado.");
    }
  };

  submitValues = async (values, actions) => {
    actions.setSubmitting(true);

    const data = values;
    data.allowExtraEmails = !!data.allowExtraEmails;
    const res = await register(data);

    if (parseInt(res.error) <= 0) {
      this.setStatus("success", "Usuário gravado com sucesso!");
      this.setState({ redirectToReferrer: true });
    } else {
      console.log("res error ", res);
      this.setStatus("error", "Erro ao gravar usuario.");
    }

    actions.setSubmitting(false);
    return false;
  };

  render() {
    // const { classes } = this.props;
    if (this.state.redirectToReferrer === true) {
      return <Redirect to="/signin" />;
    }

    const values = {
      name: "",
      email: "",
      confirmPassword: "",
      password: "",
      allowExtraEmails: "",
    };

    return (
      <React.Fragment>
        <Formik
          render={props => <Form {...props} checkemail={this.checkemail} />}
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={this.submitValues}
        />
        {this.state.status ? (
          <CustomizedSnackbars
            key={this.state.date}
            variant="success"
            status={this.state.status}
            msg={this.state.msg}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SignUpForm);
