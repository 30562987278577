import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

const newGuaritasStyle = theme => ({});

class NewGuaritas extends React.Component {
  state = {
    value: 0,
  };
  render() {
    return <h1>Nova Guarita</h1>;
  }
}

NewGuaritas.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(newGuaritasStyle)(NewGuaritas);
