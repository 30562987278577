import React from "react";
import PropTypes from "prop-types";

import withStyles from "@material-ui/core/styles/withStyles";

// import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
const dashboardStyle = theme => ({});

class Dashboard extends React.Component {
  state = {
    value: 0,
  };
  render() {
    return <h1>Dashboard</h1>;
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(dashboardStyle)(Dashboard);
