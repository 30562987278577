import React from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";

import { forms } from "../../../styles";

export const Form = props => {
  const classes = forms();

  const {
    values: { email, password },
    errors,
    touched,
    handleChange,
    isValid,
    isSubmitting,
    setFieldTouched,
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const blur = (name, e) => {
    // console.log("blur ", name);
  };

  return (
    <form onSubmit={props.handleSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="email"
            name="email"
            label="Seu e-mail"
            autoComplete="email"
            variant="outlined"
            fullWidth
            required
            value={email}
            helperText={touched.email ? errors.email : ""}
            error={touched.email && Boolean(errors.email)}
            onChange={change.bind(null, "email")}
            onBlur={blur.bind(null, "email")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="password"
            name="password"
            label="Escolha uma senha"
            variant="outlined"
            type="password"
            fullWidth
            required
            value={password}
            helperText={touched.password ? errors.password : ""}
            error={touched.password && Boolean(errors.password)}
            onChange={change.bind(null, "password")}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="remember"
                name="remember"
                color="primary"
                value="remember"
                onChange={change.bind(null, "remember")}
              />
            }
            label="Mantenha-me conectado."
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!isValid || isSubmitting}
      >
        Enviar
      </Button>
      <Grid container>
        <Grid item xs>
          <Link href="/forgot" variant="body2">
            {"Esqueceu a senha?"}
          </Link>
        </Grid>
        <Grid item>
          <Link href="/signup" variant="body2">
            {"Não possui uma conta? Cadastre-se"}
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};
