import axios from "axios";
import { getToken } from "./auth";
import { SERVER_URL } from "../configs";

/*
api.interceptors.response.use(
  response => {
    return response;
  },
  function(error) {
    // Do something with response error
    if (error.response.status === 401) {
      console.log("unauthorized, logging out ...");
    }
    return Promise.reject(error.response);
  },
);
*/

const onResponseError = async error => {
  const code = parseInt(error.response && error.response.status);
  console.log(code, "Axios onResponseError", error);
  console.log(error.response.status, "status");
  // console.log(error.config, "config");
  console.log(error.response, "response");
  // return Promise.resolve(error.response);

  if (error.response.status === 500) {
    return Promise.reject(error);
  }

  return Promise.resolve(error.response);
  /*
  if (error.response.status === 401) {
    console.log("unauthorized, logging out ...");
    return Promise.resolve(error.response);
  }
  if (code === 400) {
    console.log("400 onResponseError");
  }
  if (code === 408) {
    console.log("A timeout happened on url onResponseError");
  }
  if (code === 418) {
    console.log("A teapot onResponseError");
  }
  if (code === 428) {
    console.log("428 onResponseError");
  }
  return Promise.reject(error);
  */
};

const api = axios.create({
  baseURL: SERVER_URL,
  responseType: "json",
  timeout: 2000,
});

api.interceptors.request.use(async config => {
  const dados = await getToken();
  //console.log("interceptors", dados);
  if (dados) {
    const token = dados.token || dados.jwt || dados;
    console.log("interceptors", token);
    config.headers.Authorization = `Bearer ${token}`;
  }
  return config;
});

api.interceptors.response.use(response => {
  //console.log(response);
  return response;
}, onResponseError);

export default api;
