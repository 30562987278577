import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";

/*
const style = {
  grid: {
    padding: "0 10px !important",
  },
};
*/
const style = theme => ({
  grid: {
    padding: theme.spacing(1),
  },
});

function GridItem({ ...props }) {
  const { classes, children, ...rest } = props;
  return (
    <Grid item {...rest} className={classes.grid}>
      {children}
    </Grid>
  );
}

GridItem.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
};

export default withStyles(style)(GridItem);
