// @material-ui/icons
import Dashboard from "@material-ui/icons/Dashboard";
import Business from "@material-ui/icons/Business";
import Add from "@material-ui/icons/Add";
import List from "@material-ui/icons/List";

// core components/views for Admin layout
import DashboardPage from "../pages/Dashboard/Dashboard";

import GuaritasPage from "../pages/Guaritas/Guaritas";
import NewGuaritasPage from "../pages/Guaritas/New";
import ListGuaritasPage from "../pages/Guaritas/List";

const childrenGuaritas = [
  {
    path: "/guaritas/list",
    name: "Listar guaritas",
    icon: List,
    component: ListGuaritasPage,
    layout: "/admin",
  },
  {
    path: "/guaritas/new",
    name: "Adicionar guarita",
    icon: Add,
    component: NewGuaritasPage,
    layout: "/admin",
  },
];

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    rtlName: "لوحة القيادة",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
  },
  {
    path: "/guaritas",
    name: "Guaritas",
    rtlName: "لوحة القيادة",
    icon: Business,
    component: GuaritasPage,
    layout: "/admin",
    child: childrenGuaritas,
  },
];

export default dashboardRoutes;
