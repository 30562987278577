import React from "react";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import { FluentValidation } from "../../../helpers";

import { forms } from "../../../styles";

export const Form = props => {
  const classes = forms();

  const {
    values: { name, email, password, confirmPassword, allowExtraEmails },
    errors,
    touched,
    handleChange,
    isValid,
    isSubmitting,
    setFieldTouched,
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const blur = (name, e) => {
    if (name === "email") {
      const validator = new FluentValidation();
      validator.isEmail(e.target.value, "invalidemail");
      if (validator.isValid() === true) props.checkemail(name, e);
      return false;
    }
  };

  return (
    <form onSubmit={props.handleSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="name"
            name="name"
            label="Nome completo"
            variant="outlined"
            autoComplete="name"
            autoFocus
            fullWidth
            value={name}
            helperText={touched.name ? errors.name : ""}
            error={touched.name && Boolean(errors.name)}
            onChange={change.bind(null, "name")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="email"
            name="email"
            label="Seu e-mail"
            autoComplete="email"
            variant="outlined"
            fullWidth
            required
            value={email}
            helperText={touched.email ? errors.email : ""}
            error={touched.email && Boolean(errors.email)}
            onChange={change.bind(null, "email")}
            onBlur={blur.bind(null, "email")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="password"
            name="password"
            label="Escolha uma senha"
            variant="outlined"
            type="password"
            fullWidth
            required
            value={password}
            helperText={touched.password ? errors.password : ""}
            error={touched.password && Boolean(errors.password)}
            onChange={change.bind(null, "password")}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm Password"
            variant="outlined"
            type="password"
            fullWidth
            required
            value={confirmPassword}
            helperText={touched.confirmPassword ? errors.confirmPassword : ""}
            error={touched.confirmPassword && Boolean(errors.confirmPassword)}
            onChange={change.bind(null, "confirmPassword")}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Checkbox
                id="allowExtraEmails"
                name="allowExtraEmails"
                color="primary"
                value={allowExtraEmails}
                onChange={change.bind(null, "allowExtraEmails")}
              />
            }
            label="Receber e-mails sobre novas funcionalidades."
          />
        </Grid>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!isValid || isSubmitting}
      >
        Cadastrar
      </Button>
      <Grid container justify="flex-end">
        <Grid item>
          <Link href="/signin" variant="body2">
            {`Você já possui uma conta? Fazer login`}
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};
