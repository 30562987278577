import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import PropTypes from "prop-types";
// import { Switch, Route, Redirect } from "react-router-dom";
import PerfectScrollbar from "perfect-scrollbar";
// import Button from "@material-ui/core/Button";

import withStyles from "@material-ui/core/styles/withStyles";

// core components
import Sidebar from "../components/Sidebar/Sidebar.jsx";
import Header from "../components/Navbars/AdminNavbar.jsx";

import routes from "../routes/adminRoutes";
import image from "../assets/img/sidebar-1.jpg";

const drawerPosition = "left"; // right

const pegarRotas = function(layout = "/admin") {
  let result = [];
  for (let i = 0; i < routes.length; i++) {
    const route = routes[i];

    if (route.layout === layout) {
      result.push(route);

      if (!!route.child) {
        route.exact = true;
        let child = route.child;
        for (let ii = 0; ii < child.length; ii++) {
          result.push(child[ii]);
        }
      }
    }
  }
  return (
    <Switch>
      {result.map(function(prop, key) {
        return (
          <Route
            exact={!!prop.exact}
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      })}
    </Switch>
  );
};

const dashboardStyle = theme => ({});

class Dashboard extends React.Component {
  state = {
    mobileOpen: false,
    image: image,
    color: "red",
    hasImage: true,
    fixedClasses: "dropdown show",
  };

  mainPanel = React.createRef();

  getRoute() {
    return window.location.pathname !== "/admin/maps";
  }

  handleDrawerToggle = () => {
    this.setState({ mobileOpen: !this.state.mobileOpen });
  };

  componentDidMount() {
    if (navigator.platform.indexOf("Win") > -1) {
      let ps = new PerfectScrollbar(this.mainPanel.current);
    }
    // window.addEventListener("resize", this.resizeFunction);
  }

  render() {
    const { classes, ...rest } = this.props;

    return (
      <div className={classes.wrapper}>
        <Sidebar
          routes={routes}
          open={this.state.mobileOpen}
          anchor={drawerPosition}
          handleDrawerToggle={this.handleDrawerToggle}
          {...rest}
        />
        <div className={classes.mainPanel} ref={this.mainPanel}>
          <Header
            handleDrawerToggle={this.handleDrawerToggle}
            title={"Socket - Admin"}
          />
          <div className={classes.map}>{pegarRotas("/admin")}</div>
        </div>
      </div>
    );
  }
}

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Dashboard);
