import React from "react";
import { NavLink } from "react-router-dom";
import PropTypes from "prop-types";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import withStyles from "@material-ui/core/styles/withStyles";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

const sidebarStyle = theme => ({
  drawerPaper: {
    width: 240,
    // border: "1px #FF0000 solid",
  },
  drawertitle: {
    textAlign: "center",
    padding: "6px",
    margin: 0,
    fontSize: "16px",
  },
  item: {
    position: "relative",
    display: "block",
    textDecoration: "none",
    "&:hover,&:focus,&:visited,&": {
      color: theme.palette.primary.main,
    },
  },
  collapseitem: {},
  nested: {
    paddingLeft: theme.spacing(4),
  },
});

const ListChild = ({ ...props }) => {
  const { parent } = props;
  const { prop, open, setOpen, key, classes } = parent;
  const child = prop.child;

  const handleClick = function() {
    setOpen(!open);
  };

  return (
    <div key={key}>
      <ListItem button onClick={handleClick}>
        <ListItemIcon>
          <prop.icon color="primary" />
        </ListItemIcon>
        <ListItemText primary={prop.name} color="primary" />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse
        in={open}
        timeout="auto"
        unmountOnExit
        className={classes.collapseitem}
      >
        {child.map(function(p, k) {
          // console.log("p.path", p.path);
          return (
            <NavLink key={k} to={prop.layout + p.path} className={classes.item}>
              <ListItem
                button
                className={classes.nested}
                onClick={props.handleDrawerToggle}
              >
                <ListItemIcon>
                  <p.icon color="primary" />
                </ListItemIcon>
                <ListItemText primary={p.name} color="primary" />
              </ListItem>
            </NavLink>
          );
        })}
      </Collapse>
    </div>
  );
};

const Sidebar = ({ ...props }) => {
  const { classes, routes } = props;
  const [open, setOpen] = React.useState(false);

  const links = () => {
    return (
      <List>
        {routes.map(function(prop, key) {
          if (!prop.child) {
            return (
              <NavLink
                to={prop.layout + prop.path}
                key={key}
                className={classes.item}
              >
                <ListItem button onClick={props.handleDrawerToggle}>
                  <ListItemIcon>
                    <prop.icon color="primary" />
                  </ListItemIcon>
                  <ListItemText primary={prop.name} color="primary" />
                </ListItem>
              </NavLink>
            );
          } else {
            const obj = {
              prop: prop,
              open: open,
              setOpen: setOpen,
              key: key,
              classes: classes,
            };
            return (
              <ListChild
                key={key}
                parent={obj}
                handleDrawerToggle={props.handleDrawerToggle}
              />
            );
          }
        })}
      </List>
    );
  };

  return (
    <div>
      <Drawer
        variant="temporary"
        anchor={props.anchor}
        open={props.open}
        onClose={props.handleDrawerToggle}
        //onClick={props.handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <h3 className={classes.drawertitle}>Admin</h3>
        <Divider />
        {links()}
        <Divider />
      </Drawer>
    </div>
  );
};

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  handleDrawerToggle: PropTypes.func,
  open: PropTypes.bool,

  /*
  classes: PropTypes.object.isRequired,
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  bgColor: PropTypes.oneOf(["purple", "blue", "green", "orange", "red"]),
  logo: PropTypes.string,
  image: PropTypes.string,
  logoText: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
  open: PropTypes.bool,
  */
};

ListChild.propTypes = {
  handleDrawerToggle: PropTypes.func,
};

export default withStyles(sidebarStyle)(Sidebar);
