import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";

import SignInForm from "../../components/Forms/SignInForm";
import { forms } from "../../styles";
import { MadeWithLove } from "../../components";

import Logo from "../../assets/logo/logo-round-color-44.svg";

export default function SignIn() {
  const classes = forms();
  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className={classes.paper}>
        <Avatar className={classes.avatar}>
          <img src={Logo} alt="Domus Avatar Soluções digitais" />
        </Avatar>
        <Typography component="h1" variant="h5">
          Entrar
        </Typography>
        <SignInForm />
      </div>
      <Box mt={5}>
        <MadeWithLove />
      </Box>
    </Container>
  );
}
