import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "./form";
import { login } from "../../../services/auth";

import { CustomizedSnackbars } from "../../Message";

const styles = theme => ({});

const validationSchema = Yup.object({
  email: Yup.string("Informe seu e-mail")
    .email("Informe um e-mail válido")
    .required("e-mail é obrigatório"),
  password: Yup.string("")
    .min(6, "Sua senha deve conter mínimo de 6 caracteres")
    .required("Informe uma senha"),
});

class SignInForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      mgs: "",
      redirectToReferrer: false,
    };
  }
  componentDidMount() {
    // this.setStatus("error", "TESTE");
  }

  setStatus = (status, message) => {
    this.setState({ status: status, msg: message, date: new Date() });
  };

  submitValues = async (values, actions) => {
    actions.setSubmitting(true);

    const data = values;
    const res = await login(data);
    console.log("data login", data);
    console.log("res login", res);

    if (!res || !res.auth) {
      actions.resetForm();
      actions.setSubmitting(false);
      this.setStatus("error", "Falha na autenticação.");
      return;
    }

    this.setState({ redirectToReferrer: true });
    actions.setSubmitting(false);
    return false;
  };

  render() {
    // const { classes } = this.props;
    if (this.state.redirectToReferrer === true) {
      return <Redirect to="/" />;
    }

    const values = {
      email: "",
      password: "",
    };

    return (
      <React.Fragment>
        <Formik
          render={props => <Form {...props} />}
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={this.submitValues}
        />
        {this.state.status ? (
          <CustomizedSnackbars
            key={this.state.date}
            variant="success"
            status={this.state.status}
            msg={this.state.msg}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(SignInForm);
