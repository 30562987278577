import React from "react";
import PropTypes from "prop-types";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import Icon from "@material-ui/core/Icon";

import ClouldDone from "@material-ui/icons/CloudDone";
import ClouldOff from "@material-ui/icons/CloudOff";

import withStyles from "@material-ui/core/styles/withStyles";

const IconOnline = props => {
  const { online, ...rest } = props;

  if (props.online === true) {
    return <Icon color="primary" component={ClouldDone} {...rest} />;
  } else {
    return <Icon color="error" component={ClouldOff} {...rest} />;
  }
};

const tableTableRowStyle = theme => ({});

const TableRowDefault = props => {
  const { headRows, myRow, useCheckbox, ...rest } = props;

  const renderCheckbok = () => {
    if (!!useCheckbox) {
      return (
        <TableCell padding="checkbox" key={0}>
          <Checkbox
            checked={useCheckbox.checked}
            inputProps={useCheckbox.inputProps}
            onChange={
              typeof useCheckbox.onChange === "function"
                ? useCheckbox.onChange
                : null
            }
          />
        </TableCell>
      );
    }
    return null;
  };

  const makeTypes = (type, rowValue) => {
    //console.log(type[0].frefixId + "-" + rowValue.id);
    return (
      <IconOnline
        online={rowValue.status}
        id={type[0].frefixId + "-" + rowValue.id}
      />
    );
  };

  const renderWhithHead = () => {
    return headRows.map((head, i) => {
      let id = head.id || i;
      if (!!head.hidden === true) return null;
      if (myRow.hasOwnProperty(head.id) || !!head.types) {
        const cellValue = myRow[head.id] || head.defaultValue || "";
        return (
          <TableCell
            key={id}
            align={head.align || "center"}
            padding={head.disablePadding ? "none" : "default"}
          >
            {!!head.types ? makeTypes(head.types, myRow) : cellValue}
          </TableCell>
        );
      } else {
        return <TableCell key={id}>vazio</TableCell>;
      }
    });
  };

  return (
    <TableRow {...rest}>
      {renderCheckbok()}
      {headRows
        ? renderWhithHead()
        : Object.keys(myRow).map((cel, i) => {
            return (
              <TableCell align="center" key={i}>
                {myRow[cel]}
              </TableCell>
            );
          })}
    </TableRow>
  );
};

TableRowDefault.propTypes = {
  headRows: PropTypes.array,
  myRow: PropTypes.object,
  useCheckbox: PropTypes.object,
};

export default withStyles(tableTableRowStyle)(TableRowDefault);
