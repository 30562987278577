import theme from "./theme";

const custom = {
  bg1: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },
};

export default custom;
