import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";

import { red } from "@material-ui/core/colors";

const cardStyle = theme => ({
  card: {
    maxWidth: "100%",
    //margin: "10px 8px",
  },

  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
});

function CardDefault({ ...props }) {
  const {
    classes,
    className,
    children,
    plain,
    profile,
    chart,
    title,
    subtitle,
    ...rest
  } = props;
  // const [expanded, setExpanded] = React.useState(false);
  return (
    <Card className={classes.card} {...rest}>
      {!!title && !!subtitle ? (
        <CardHeader title={title} subheader={subtitle} />
      ) : null}
      <CardContent>{children}</CardContent>
    </Card>
  );
}

CardDefault.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  plain: PropTypes.bool,
  profile: PropTypes.bool,
  chart: PropTypes.bool,
  children: PropTypes.node,
};

export default withStyles(cardStyle)(CardDefault);
