import React from "react";
import PropTypes from "prop-types";
import Toolbar from "@material-ui/core/Toolbar";

import withStyles from "@material-ui/core/styles/withStyles";

const tableToolbarStyle = theme => ({});

const TableToolbar = props => {
  const { numSelected, ...rest } = props;
  return <Toolbar {...rest}>Toolbar {numSelected}</Toolbar>;
};

TableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default withStyles(tableToolbarStyle)(TableToolbar);
