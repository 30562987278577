import { red } from "@material-ui/core/colors";
import { createMuiTheme } from "@material-ui/core/styles";

// A custom theme for this app
const theme = createMuiTheme({
  palette: {
    primary: {
      main: "rgba(14,40,65,1)",
      light: "rgba(38,62,84,1)",
      dark: "rgba(7,19,32,1)",
      contrastText: "#fff",
    },
    secondary: {
      main: "rgba(13,126,204,1)",
      contrastText: "#fff",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "rgba(230,230,230,1)",
    },
  },
  typography: {
    useNextVariants: true,
  },
});

export default theme;
