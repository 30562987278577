import React from "react";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Link from "@material-ui/core/Link";
import TextField from "@material-ui/core/TextField";
import { forms } from "../../../styles";

export const Form = props => {
  const classes = forms();

  const {
    values: { email },
    errors,
    touched,
    handleChange,
    isValid,
    isSubmitting,
    setFieldTouched,
  } = props;

  const change = (name, e) => {
    e.persist();
    handleChange(e);
    setFieldTouched(name, true, false);
  };

  const blur = (name, e) => {
    // console.log("blur ", name);
  };

  return (
    <form onSubmit={props.handleSubmit} className={classes.form}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            id="email"
            name="email"
            label="Seu e-mail"
            autoComplete="email"
            variant="outlined"
            fullWidth
            required
            value={email}
            helperText={touched.email ? errors.email : ""}
            error={touched.email && Boolean(errors.email)}
            onChange={change.bind(null, "email")}
            onBlur={blur.bind(null, "email")}
          />
        </Grid>
      </Grid>
      <br />
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        className={classes.submit}
        disabled={!isValid || isSubmitting}
      >
        ENVIAR
      </Button>
      <Grid container>
        <Grid item>
          <Link href="/signin" variant="body2">
            {"Fazer login"}
          </Link>
        </Grid>
      </Grid>
    </form>
  );
};
