import api from "./api";
import { TOKEN_KEY, DEFAULT_EXPIRE_MINUTES } from "../configs";
import { FluentValidation } from "../helpers";

import moment from "moment";
import "moment/locale/pt-br";
moment.locale("pt-BR");

export const checkValidToken = async token => {
  try {
    let response = await api.post("users/checktoken", { token: token || null });
    if (!response) return false;

    console.log("checkValidToken", response, response.status);

    if (response.status !== 200) {
      removeToken();
      return false;
    }
    let dados = getToken();
    dados.expire_at =
      response.data.expire_at ||
      moment()
        .add(DEFAULT_EXPIRE_MINUTES, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");

    return saveToken(dados);
  } catch (e) {
    return false;
  }
};

export const isAuthenticated = () => {
  let dados = getToken();
  if (!dados || !dados.expire_at) return false;

  let exp = moment(dados.expire_at, "YYYY-MM-DD HH:mm:ss");
  let agora = moment();

  const d = agora.diff(exp, "minutes");
  console.log(
    "check",
    exp.format("YYYY-MM-DD HH:mm:ss"),
    agora.format("YYYY-MM-DD HH:mm:ss"),
    d,
  );
  if (d > DEFAULT_EXPIRE_MINUTES) {
    // console.log("aaaa", checkValidToken(dados.token));
  }

  //console.log("check validade token", d);
  return dados ? !!dados.token : false;
};

export const removeToken = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const saveToken = obj => {
  try {
    let dados = typeof obj === "object" ? JSON.stringify(obj) : obj;
    localStorage.setItem(TOKEN_KEY, dados);
    return true;
  } catch (error) {
    console.log("saveToken", error);
    return false;
  }
};

export const getToken = () => {
  try {
    let dados = JSON.parse(localStorage.getItem(TOKEN_KEY));
    return dados || undefined;
  } catch (error) {
    removeToken();
    console.log("getToken", error);
    return;
  }
};

export const logout = () => {
  // tenta revogar o tokem
  //..
  // Remove do disco
  removeToken();
};

export const forgot = () => {
  return {
    error: 0,
    message: "E-mail enviado com sucesso!",
  };
};

export const login = async data => {
  let response;
  const normalizeReturns = (status, auth, message) => {
    return {
      status: status || 500,
      auth: auth || false,
      message: message || "",
    };
  };

  try {
    const t = new Date().getTime();
    response = await api.post("users/auth?t=" + t, data);
    if (!response) return normalizeReturns(500, false, "no response");
  } catch (e) {
    return normalizeReturns(500, false, e);
  }

  if (response.status === 200 || response.status === 201) {
    if (response.data.success === true) {
      const minutos = !!data.remember === false ? DEFAULT_EXPIRE_MINUTES : 1440;
      const expire = moment()
        .add(minutos, "minutes")
        .format("YYYY-MM-DD HH:mm:ss");

      const dados = {
        token: response.data.token,
        name: response.data.data.nome || "",
        email: response.data.data.email || "",
        expire_at: response.data.expire_at || expire,
      };

      saveToken(dados);
    }
    return normalizeReturns(
      response.status,
      !!response.data.success,
      response.data.message,
    );
  }

  return normalizeReturns(response.status, false, response.data.message);
};

export const register = async data => {
  try {
    let response = await api.post("users/register", data);
    console.log(response);
    return response.data;
  } catch (e) {
    return {
      error: 500,
      message: e,
    };
  }
};

export const checkEmail = async data => {
  const email = data || data.email;

  const validar = new FluentValidation();
  validar.isEmail(email, "E-mail inválido");

  if (validar.isValid() === false) {
    let r = {
      error: 1,
      message: "e-mail inv",
    };
    return r;
  }

  try {
    let response = await api.post(window.SERVER_URL + "/users/checkemail", {
      email: data,
    });

    if (!response) return { error: 1, exists: false };

    if (response.data) {
      const erro = response.data.error;
      if (erro > 0) return { error: erro, exists: false };
      return {
        error: 0,
        exists: response.data.message.exists || false,
      };
    }
  } catch (e) {
    console.log(e);
    return {
      error: 3,
      exists: false,
      message: e,
    };
  }

  //return { error: 0, message: "teste message" };
};

// export const isAuthenticated = () => true;
