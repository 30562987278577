import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { GridContainer, GridItem, Card, TableDefault } from "../../components";

const urlAPI = "guaritas/list";

const headTable = [
  {
    id: "status",
    align: "center",
    disablePadding: true,
    label: "STATUS",
    types: [{ type: "icon", frefixId: "icon-guaritaonline" }],
  },
  {
    id: "id",
    numeric: true,
    disablePadding: true,
    label: "ID",
    align: "center",
    hidden: true,
  },
  { id: "nome", align: "left", disablePadding: true, label: "Nome" },
  { id: "mac", align: "left", disablePadding: true, label: "MAC" },
  {
    id: "code",
    align: "left",
    disablePadding: true,
    label: "CODE",
    defaultValue: "-",
  },
];

const listGuaritasStyle = theme => ({});

class ListGuaritas extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: [],
      numSelected: 0,
    };
  }

  /*
  getDados() {
    api.get('guaritas/list')
    console.log("getDados", "peguei");
    return "carregou";
  }
*/
  setSelected2(newSelecteds) {
    this.setState({ selected: newSelecteds || [] });
    this.setState({ numSelected: newSelecteds.length || 0 });
    console.log("newSelecteds", this.state.numSelected);
  }

  render() {
    return (
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <Card>
            <TableDefault
              headTable={headTable}
              urlAPI={urlAPI}
              setSelected={this.setSelected2}
            />
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Card title={"Teste de card"} subtitle={"Subtitulo teste"}>
            <p>TESTE</p>
          </Card>
        </GridItem>
      </GridContainer>
    );
  }
}
/*
ListGuaritas.propTypes = {
  classes: PropTypes.object.isRequired,
};
*/
export default withStyles(listGuaritasStyle)(ListGuaritas);
