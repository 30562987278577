//const SERVER_URL = "http://fabrica.avatarsolucoesdigitais.com.br:3000";
// const SERVER_URL = "http://localhost:3000";
const SERVER_URL = "https://cloud.avatarsolucoesdigitais.com.br";
const TOKEN_KEY = "@DomusSocket-Token";
const SIGNUP_ENDPOINT = `${window.SERVER_URL}/users/register`;
const LOGIN_ENDPOINT = `${window.SERVER_URL}/users/auth`;
const DEFAULT_EXPIRE_MINUTES = 2;

export {
  SERVER_URL,
  TOKEN_KEY,
  SIGNUP_ENDPOINT,
  LOGIN_ENDPOINT,
  DEFAULT_EXPIRE_MINUTES,
};
