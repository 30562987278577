import React from "react";
import ReactDOM from "react-dom";

// CssBaseline kickstart an elegant, consistent, and simple baseline to build upon.
import CssBaseline from "@material-ui/core/CssBaseline";

// Tema pronto
import { ThemeProvider } from "@material-ui/styles";

import App from "./App";

import { theme } from "../src/styles";

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <App />
  </ThemeProvider>,
  document.getElementById("root"),
);
