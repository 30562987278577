import React from "react";
import PropTypes from "prop-types";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Checkbox from "@material-ui/core/Checkbox";

import withStyles from "@material-ui/core/styles/withStyles";

const tableHeadStyle = theme => ({});

function TableHeadDefault(props) {
  const {
    // classes,
    headRows,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;

  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ "aria-label": "select all desserts" }}
          />
        </TableCell>
        {headRows.map((row, i) => {
          if (!!row.hidden === false) {
            return (
              <TableCell
                key={row.id || i}
                align={row.align || "center"}
                padding={row.disablePadding ? "none" : "default"}
                sortDirection={orderBy === row.id ? order : false}
              >
                <TableSortLabel
                  active={orderBy === row.id}
                  direction={order}
                  onClick={createSortHandler(row.id)}
                >
                  {row.label}
                  {orderBy === row.id ? null : null}
                </TableSortLabel>
              </TableCell>
            );
          }
        })}
      </TableRow>
    </TableHead>
  );
}

/*
const TableHeadDefault = props => {
  const { headRows } = props;
  return (
    <TableHead>
      <TableRow>
        {headRows.map(row => (
          <TableCell key={row.name}>{row.label}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
};
*/

TableHeadDefault.propTypes = {
  classes: PropTypes.object.isRequired,
  headRows: PropTypes.array.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number.isRequired,
};

export default withStyles(tableHeadStyle)(TableHeadDefault);
