import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import withStyles from "@material-ui/core/styles/withStyles";
import { Formik } from "formik";
import * as Yup from "yup";
import { Form } from "./form";
import { forgot } from "../../../services/auth";
import { CustomizedSnackbars } from "../../Message";

const styles = theme => ({});

const validationSchema = Yup.object({
  email: Yup.string("Informe seu e-mail")
    .email("Informe um e-mail válido")
    .required("e-mail é obrigatório"),
});

class ForgotForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      status: "",
      mgs: "",
      redirectToReferrer: false,
    };
  }
  componentDidMount() {
    // this.setStatus("error", "TESTE");
  }

  setStatus = (status, message) => {
    this.setState({ status: status, msg: message, date: new Date() });
  };

  submitValues = async (values, actions) => {
    actions.setSubmitting(true);

    const data = values;
    const res = await forgot(data);

    if (parseInt(res.error) <= 0) {
      this.setStatus("success", "E-mail enviado com sucesso!");
      this.setState({ redirectToReferrer: true });
    } else {
      this.setStatus("error", "Falha na autenticação.");
    }
    actions.setSubmitting(false);
    return false;
  };

  render() {
    // const { classes } = this.props;
    if (this.state.redirectToReferrer === true) {
      return <Redirect to="/signin" />;
    }

    const values = {
      email: "",
    };

    return (
      <React.Fragment>
        <Formik
          render={props => <Form {...props} />}
          initialValues={values}
          validationSchema={validationSchema}
          onSubmit={this.submitValues}
        />
        {this.state.status ? (
          <CustomizedSnackbars
            key={this.state.date}
            variant="success"
            status={this.state.status}
            msg={this.state.msg}
          />
        ) : null}
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(ForgotForm);
