import React, { Component } from "react";
// import PropTypes from "prop-types";

//import withStyles from "@material-ui/core/styles/withStyles";
import { withStyles } from "@material-ui/core/styles";
//makeStyles

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TablePagination from "@material-ui/core/TablePagination";
// import TableSortLabel from "@material-ui/core/TableSortLabel";

import Modal from "@material-ui/core/Modal";

// import Typography from "@material-ui/core/Typography";

// my components

import { TableHeadDefault, TableToolbar, TableRowDefault } from "../index";

import api from "../../services/api";

const tableStyle = theme => ({
  root: {
    transform: "translateZ(0)",
    height: "100%",
    flexGrow: 1,
  },
  modal: {
    display: "flex",
    padding: theme.spacing(1),
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // width: 400,
    // backgroundColor: theme.palette.background.paper,
    border: 0,
    color: "#FFF",
    // boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 4),
  },
});

class TableDefault extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      error: null,
      dense: true,
      selected: [],
      header: props.headTable,
      data: [],
      rowsPerPage: 0,
      page: 0,
      total: 0,
      orderBy: "nome",
      order: "asc",
    };

    this.rootRef = React.createRef(); //React.useRef();
    this.classes = props.classes;

    this.urlAPI = props.urlAPI;
  }
  getAllPaginate() {
    const opt = {
      page: this.state.page + 1,
      size: this.state.rowsPerPage,
      // order: this.state.order,
      orderby: this.state.orderBy,
    };
    return opt;
  }

  getApiData(opt) {
    const self = this;
    const params = opt ? { params: opt } : undefined;
    this.setState({ isLoading: true });
    api.get(self.urlAPI, params).then(response => {
      const sets = {
        data: response.data.docs || [],
        total: response.data.total || 0,
        page: response.data.page - 1 || 0,
        rowsPerPage: response.data.size,
        order: response.data.order,
        orderBy: response.data.orderby,
        isLoading: false,
      };
      self.setState(sets);
      console.log(response.data);
      console.log(sets);
    });
  }

  setSelected(newSelecteds) {
    this.setState({ selected: newSelecteds });
  }

  isSelected(name) {
    return this.state.selected.indexOf(name) !== -1;
  }

  handleChangePage = (event, newPage) => {
    this.getApiData({
      page: newPage + 1 || 0,
      size: this.state.rowsPerPage,
      order: this.state.order,
      orderby: this.state.orderBy,
    });
  };

  handleChangeRowsPerPage = event => {
    this.getApiData({
      size: event.target.value || 2,
      // order: this.state.order,
      orderby: this.state.orderBy,
    });
  };

  handleRequestSort = (event, property) => {
    const isDesc = this.state.orderBy === property && this.state.order === "desc";
    /*
    this.setState({
      order: isDesc ? "desc" : "asc",
      orderBy: property,
    });
    */

    this.getApiData({
      page: this.state.page + 1,
      size: this.state.rowsPerPage,
      order: isDesc ? "asc" : "desc",
      orderby: property,
    });
  };

  handleClick(event, name) {
    const selected = this.state.selected;

    const selectedIndex = selected.indexOf(name);

    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    this.setSelected(newSelected);
  }

  componentDidMount() {
    this.getApiData();
  }

  render() {
    const {
      data,
      isLoading,
      error,
      selected,
      dense,
      header,
      order,
      orderBy,
    } = this.state;
    const { classes, rootRef, handleRequestSort } = this;

    if (error) {
      return <p>{error.message}</p>;
    }

    const handleCheckClick = e => {
      if (e.target.checked === true) console.log("clcick", e.target.value);
    };
    return (
      <div className={classes.root}>
        <TableToolbar numSelected={selected.length} />
        <Table size={dense ? "small" : "medium"} ref={rootRef}>
          <TableHeadDefault
            headRows={header}
            numSelected={selected.length}
            rowCount={header.length}
            onRequestSort={handleRequestSort}
            order={order}
            orderBy={orderBy}
            //onSelectAllClick={/*handleSelectAllClick*/}
          />
          <TableBody>
            {data.map((row, index) => {
              const isItemSelected = this.isSelected(row.id);
              const labelId = `enhanced-table-checkbox-${index}`;
              const useCheckbox = {
                checked: isItemSelected,
                inputProps: {
                  "aria-labelledby": labelId,
                  value: row.id,
                },
                onChange: handleCheckClick,
              };
              return (
                <TableRowDefault
                  key={row.id}
                  hover={true}
                  onClick={event => this.handleClick(event, row.id)}
                  role="checkbox"
                  aria-checked={isItemSelected}
                  tabIndex={-1}
                  selected={isItemSelected}
                  useCheckbox={useCheckbox}
                  myRow={row}
                  headRows={header}
                />
              );
            })}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[2, 5, 10, 25]}
          component="div"
          count={this.state.total}
          rowsPerPage={this.state.rowsPerPage}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
        <Modal
          className={classes.modal}
          open={isLoading}
          disablePortal
          disableEnforceFocus
          disableAutoFocus
          disableEscapeKeyDown
          // disableScrollLock
          container={() => rootRef.current}
        >
          <div className={classes.paper}>
            <p>Carregando...</p>
          </div>
        </Modal>
      </div>
    );
  }
}

export default withStyles(tableStyle)(TableDefault);
